<template>
	<BaseLoading v-if="edit.isLoading" is-full-page />
	<div
		v-else
		class="main-wrapper mb-5"
	>
		<FormCustomer
			is-edit
			:is-submitting="edit.isSubmitting"
			:is-active="edit.data.isActive"
			:first-name="edit.data.firstName"
			:last-name="edit.data.lastName"
			:email="edit.data.email"
			:phone="edit.data.phone"
			:store="edit.data.store"
			:staff-id="edit.data.staffId"
			:customer-group="edit.data.customerGroup"
			:customer-status="edit.data.customerStatus"
			:provider="edit.data.provider"
			:birthdate="edit.data.birthdate"
			@onConfirm="handleConfirm"
			@onResendVerificationLink="handleResendVerificationLink"
		/>
		<hr class="my-5">
		<h4 class="mb-4">
			Shipping address
		</h4>
		<BaseNoItemBanner
			v-if="!edit.data.shippingAddresses.length"
			text="There is no address added."
		/>
		<div
			v-for="address in edit.data.shippingAddresses"
			v-else
			:key="address.id"
			class="border border-gray-400 rounded p-3 mb-3"
		>
			<div class="d-flex justify-content-between align-items-center">
				<strong>{{ address.addressName }}</strong>
				<CBadge
					v-if="address.isDefault"
					color="info"
				>
					Default
				</CBadge>
			</div>
			<div class="typo-body-2 color-gray-900">
				{{ address.customerInfo }}
			</div>
			<div class="typo-body-2 color-gray-500 text-break">
				{{ address.customerAddress }}<br>
				{{ address.customerDetail }}
			</div>
		</div>
		<hr class="my-5">
		<h4 class="mb-4">
			Billing address
		</h4>
		<BaseNoItemBanner
			v-if="!edit.data.billingAddresses.length"
			text="There is no address added."
		/>
		<div
			v-for="address in edit.data.billingAddresses"
			v-else
			:key="address.id"
			class="border border-gray-400 rounded p-3 mb-3"
		>
			<div class="d-flex justify-content-between align-items-center">
				<strong>{{ address.addressName }}</strong>
				<CBadge
					v-if="address.isDefault"
					color="info"
				>
					Default
				</CBadge>
			</div>
			<div class="typo-body-2 color-gray-900">
				{{ address.customerInfo }}
			</div>
			<div class="typo-body-2 color-gray-500 text-break">
				{{ address.customerAddress }}<br>
				{{ address.customerDetail }}
			</div>
		</div>
		<hr class="my-5">
		<h4 class="mb-4">
			Tax invoice address
		</h4>
		<BaseNoItemBanner
			v-if="!edit.data.taxAddresses.length"
			text="There is no address added."
		/>
		<div
			v-for="address in edit.data.taxAddresses"
			v-else
			:key="address.id"
			class="border border-gray-400 rounded p-3 mb-3"
		>
			<div class="d-flex justify-content-between align-items-center">
				<strong>{{ address.addressName }}</strong>
				<CBadge
					v-if="address.isDefault"
					color="info"
				>
					Default
				</CBadge>
			</div>
			<div class="typo-body-2 color-gray-900">
				{{ address.customerInfo }}
			</div>
			<div class="typo-body-2 color-gray-500 text-break">
				{{ address.customerAddress }}<br>
				{{ address.customerDetail }}
			</div>
		</div>
		<hr class="my-5">
		<div class="d-flex justify-content-between align-items-center">
			<h4>Order History</h4>
			<router-link
				:to="{ name: 'OrderList', query: { customer_id: $route.params.id }}"
				class="btn btn-secondary"
			>
				View order history
			</router-link>
		</div>
	</div>
</template>

<script>
import FormCustomer from '@/components/FormCustomer.vue';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'CustomerEdit',
	components: {
		FormCustomer,
	},
	computed: {
		...mapState('customers', {
			edit: 'edit',
		}),
	},
	async created() {
		const customerId = this.$route.params.id;

		await Promise.all([
			this.getWarehouseList({ per_page: 'all' }),
			this.getCustomerGroupList(),
			this.getCustomer(customerId),
		]);

		this.setPageTitle({
			title: this.edit.data.fullname,
			isShow: true,
		});
	},
	methods: {
		...mapActions({
			getCustomer: 'customers/getCustomer',
			updateCustomer: 'customers/updateCustomer',
			resendVerificationLink: 'customers/resendVerificationLink',
			getCustomerGroupList: 'customerGroups/getCustomerGroupList',
			getWarehouseList: 'warehouses/getWarehouseList',
			setPageTitle: 'pageTitle/setPageTitle',
		}),
		async handleConfirm(params) {
			const id = this.$route.params.id;
			await this.updateCustomer({ id, params });
		},
		async handleResendVerificationLink() {
			const customerId = this.$route.params.id;
			await this.resendVerificationLink(customerId);
		},
	},
};
</script>
